import HeroSection from "./components/home/HeroSection";
import "./App.css";

function App() {
  return (
    <div>
      <HeroSection />
    </div>
  );
}

export default App;
